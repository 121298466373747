:root {
  --header-size: 56px;
}

body {
  margin: 0;
  font-size: 1rem;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e1e1e1;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100vh;
  @apply flex flex-col;
}

.App {
  padding-top: var(--header-size);
  height: 100vh;
  min-height: 100vh;
  @apply flex flex-col flex-grow;
}

.body {
  @apply flex flex-col flex-grow;
}

.card {
  @apply rounded-lg shadow-lg bg-light p-4;
}

label {
  @apply my-2;
}
